<script setup>
import NavbarTop from './components/NavbarTop'
import { RouterView } from 'vue-router'
import CloseIcon from './components/icons/CloseIcon.vue'
import { RouterLink } from 'vue-router'
import { userStore } from './stores/user'
import { checkUserToken, clearUserAuth } from './utils/handleSession'
</script>

<template>
  <div class="tw-h-full tw-pb-4">
    <div
      ref="popup"
      v-if="showSidebar"
      @click="onShowSidebar"
      class="tw-absolute tw-z-10 tw-bg-dark-grey tw-opacity-70 tw-overflow-hidden"
    ></div>
    <div
      ref="sidebar"
      :class="[
        showSidebar ? 'tw-left-0' : '-tw-left-full',
        'tw-transition-all tw-duration-500 tw-w-[376px] tw-bg-white tw-fixed tw-z-20 tw-top-0 tw-bottom-0 tw-rounded-tr-[32px]'
      ]"
    >
      <div class="tw-flex tw-justify-end tw-mr-5 tw-mt-4">
        <CloseIcon class="tw-cursor-pointer" @click="onShowSidebar" />
      </div>
      <ul class="tw-flex tw-flex-col tw-gap-6 tw-font-lg tw-px-8">
        <li
          @click="onShowSidebar"
          class="tw-text-xl tw-font-light tw-cursor-pointer tw-text-dark-grey hover:tw-underline hover:tw-text-blue tw-underline-offset-4"
        >
          <RouterLink to="/dashboard">Dashboard</RouterLink>
        </li>
        <li
          @click="onShowSidebar"
          class="tw-text-xl tw-font-light tw-cursor-pointer tw-text-dark-grey hover:tw-underline hover:tw-text-blue tw-underline-offset-4"
        >
          <RouterLink to="/orders">Orders</RouterLink>
        </li>
        <li
          @click="onShowSidebar"
          class="tw-text-xl tw-font-light tw-cursor-pointer tw-text-dark-grey hover:tw-underline hover:tw-text-blue tw-underline-offset-4"
        >
          <RouterLink to="/settings">Settings</RouterLink>
        </li>
      </ul>
    </div>
    <div class="tw-h-full tw-flex tw-flex-col">
      <div class="tw-w-full tw-h-40 md:tw-h-20">
        <NavbarTop @showSidebar="onShowSidebar" :showSidebar="showSidebar" />
      </div>
      <RouterView />
      <footer v-if="!isLogin && !showSidebar && !isSetPassword" class="tw-py-6">
        <div class="tw-flex tw-gap-2 tw-justify-center">
          <div
            class="tw-underline tw-underline-offset-4 tw-cursor-pointer tw-text-blue tw-text-base"
            @click="goToTermsOfUse"
          >
            Terms of Use
          </div>
          <div class="tw-border tw-border-r-dark-grey"></div>
          <div
            class="tw-underline tw-underline-offset-4 tw-cursor-pointer tw-text-blue tw-text-base"
            @click="goToPrivacyPolicy"
          >
            Privacy Policy
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
    RouterLink
  },
  data() {
    return {
      showSidebar: false,
      isLogin: true,
      isSetPassword: false,
      isAuthenticated: false
    }
  },
  methods: {
    onShowSidebar() {
      const scrollWidth = document.documentElement.scrollWidth
      const scrollHeight = document.documentElement.scrollHeight
      // Apply the width and height to the sidebar and popup

      setTimeout(() => {
        if (!this.$refs.popup || !this.$refs.sidebar) return
        this.$refs.popup.style.width = `${scrollWidth}px`
        this.$refs.popup.style.height = `${scrollHeight}px`
        this.$refs.sidebar.style.height = `${scrollHeight}px`
      }, 0)

      this.showSidebar = !this.showSidebar
    },
    async resetCookie() {
      const hasUserToken = checkUserToken()
      if (!hasUserToken) {
        //remove event listeners
        this.removeEventListeners()

        // Clear user data
        const { clearUser } = userStore()
        clearUser()
        await clearUserAuth()

        // Redirect to login
        this.$router.push('/')
      }
    },
    goToPrivacyPolicy() {
      window.open('https://www.poweredbyash.com/privacy-policy', '_blank')
    },
    goToTermsOfUse() {
      window.open('https://www.poweredbyash.com/terms-of-use', '_blank')
    }
  },
  async created() {
    const user = userStore()

    user.$subscribe(async (mutation, state) => {
      if (!state.user.accessToken) {
        this.showSidebar = false
      }
    })
  },
  watch: {
    $route(route) {
      this.showSidebar = !!this.showSidebar
      this.isLogin = route.path === '/login'
    }
  }
}
</script>

<style>
.toast-custom-error {
  background-color: #ea5619 !important;
}

.toast-custom-success {
  background-color: #18ac76 !important;
}

.toast-custom-info {
  background-color: #3d81d0 !important;
}

.toast-custom-warning {
  background-color: #ffb85f !important;
}
</style>
